.dbr-scan div:nth-child(1){
    background-color: #cfe8fc !important;
}

.dce-btn-close{
    display: none;
}

.dce-sel-camera{
    display: none;
}

.dce-sel-resolution{
    display: none;
}

.dbrScanner-msg-poweredby{
    display: none;
}